import { API_WS } from './URL'
import React, {useState, useEffect  } from 'react';

function useWebsocket(session, setRandomness, loggedIn, died, score, pos, jumps) {

    const [socket, setSocket] = useState(null);
    const [active, setActive] = useState(false);
    const [ready, setReady] = useState(false);
    const [internalScore, setInternalScore] = useState(0);

    const onOpen = () => {
        console.log("Drone escape connected!");
        setActive(true);
        getRandomness();
    };

    const onMsg = (event) => {
        let data = JSON.parse(event.data);
        if (data["6PxBPDAM"] === "FGRvdwER") {
            setRandomness(data["FGRvdwER"]);
        }
    };

    const onClose = (event) => {
        if (event.wasClean) {
            console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            console.log('[close] Connection died');
        }
        setActive(false);
        setReady(false);
    };

    const getRandomness = () => {
        // socket.send(JSON.stringify({
        //     "6PxBPDAM": "FGRvdwER",
        // }));
    }

    const notifyDied = () => {
        // socket.send(JSON.stringify({
        //     "6PxBPDAM": "7cLmGJxH",
        // }));
    }
    
    const notifyScore = (x) => {
        // socket.send(JSON.stringify({
        //     "6PxBPDAM": "J9kWhvCM",
        //     "kz9kGYYa": x,
        //     "Hzhbw9su": new Date().getTime() / 1000,
        // }));
    }

    const notifyJump = (x) => {
        // socket.send(JSON.stringify({
        //     "6PxBPDAM": "xm8fzkpV",
        //     "kz9kGYYa": x,
        //     "Hzhbw9su": new Date().getTime() / 1000,
        // }));
    }

    useEffect(_ => {
        if (loggedIn && !!session) {
            // setSocket(new WebSocket(API_WS + session));
            setReady(true);
            setActive(true);
            setRandomness(0);
        }
    }, [session, loggedIn]);

    // useEffect(_ => {
    //     if (active && loggedIn && died) {
    //         notifyDied();
    //         getRandomness();
    //     }
    // }, [active, died, loggedIn]);

    // useEffect(_ => {
    //     if (score > internalScore) {
    //         notifyScore(pos);
    //     }
    //     setInternalScore(score);
    // }, [score, pos]);

    // useEffect(_ => {
    //     if (jumps > 0) notifyJump(pos);
    // }, [jumps, pos]);

    // useEffect(_ => {
    //     if (ready) {
    //         socket.onopen = onOpen;
    //         socket.onmessage = onMsg;
    //         socket.onclose = onClose;
    //         socket.onerror = function(error) {
    //             console.error(`[error] ${error.message}`);
    //         };
    //     }
    // }, [ready]);
   
    return {

    }
    
}


export default ({ session, setRandomness, loggedIn, died, score, pos, jumps }) => {
    const {} = useWebsocket(session, setRandomness, loggedIn, died, score, pos, jumps);
    return (<></>)
}