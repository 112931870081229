import { PIXI } from 'expo-pixi';
import { Container, extras, Sprite } from 'pixi.js';
import { AsyncStorage, PixelRatio } from 'react-native';

const { Rectangle, Texture } = PIXI;
import source from '../assets/spritesheet.png';
import setupSpriteSheetAsync from './setupSpriteSheetAsync';
import sprites from './sprites';

import expl_1 from '../assets/Explode_1.png';
import expl_2 from '../assets/Explode_2.png';
import expl_3 from '../assets/Explode_3.png';
import expl_4 from '../assets/Explode_4.png';
import expl_5 from '../assets/Explode_5.png';
import expl_6 from '../assets/Explode_6.png';
import expl_7 from '../assets/Explode_7.png';

const { TilingSprite, AnimatedSprite } = extras;

const scale = PixelRatio.get();
console.log("SCALE:", scale, PixelRatio.get());

const Settings = {
  playerFallSpeed: 9.2 * scale,
  playerHorizontalPosition: 100 * scale,
  playerVerticalPosition: 300 * scale,
  playerMaxVelocity: -2 * scale,
  // pipeWidth: 78 * scale,
  groundHeight: 100 * scale,
  // pipeHeight: 480 * scale,
  playerGravity: 0.55 * scale,
  minPipeHeight: 20 * scale,
  pipeVerticalGap: 160 * scale, //180 is pretty legit
  gameSpeed: 10 * 0.25 * scale,
};



class FlappySprite extends Sprite {
  constructor(...args) {
    super(...args);
    this.scale.set(scale);
  }
}

class ExplosionSprite extends AnimatedSprite {
  constructor(textures) {
    super(textures);
    this.animationSpeed = 0.3;
    this.anchor.set(0.5);
    this.width = 4 * 24 * scale;
    this.height = 4 * 24 * scale;
    this.loop  = false;
  }

  onComplete = () => {
    this.alpha = 0;
  }

  restart = (x, y) => {
    this.alpha = 1;
    this.gotoAndPlay(0);
    this.rotation = 0;
    this.position.x = x;
    this.position.y = y;
  };

}


class Ground extends TilingSprite {
  constructor(texture) {
    super(texture, Settings.width, Settings.groundHeight);
    this.tileScale.set(scale * 2);
    this.position.x = 0;
    this.position.y = Settings.skyHeight;
  }
}

class Background extends TilingSprite {
  constructor(texture) {
    super(texture);
    this.tileScale.set(Settings.height / 192);
    this.position.x = 0;
    this.position.y = 0;
    this.width = Settings.width;
    this.height = Settings.height;
  }
}

function boxesIntersect(a, b, paddingA = 0) {
  const ab = a.getBounds();
  ab.x += paddingA;
  ab.width -= paddingA * 2;
  ab.y += paddingA;
  ab.height -= paddingA * 2;

  const bb = b.getBounds();
  return (
    ab.x + ab.width > bb.x &&
    ab.x < bb.x + bb.width &&
    ab.y + ab.height > bb.y &&
    ab.y < bb.y + bb.height
  );
}
class PipeContainer extends Container {
  pipes = [];
  pipeIndex = 0;

  constructor(pipeTexture) {
    super();
    this.pipeTexture = pipeTexture;
    this.position.x = Settings.width + Settings.pipeWidth / 2;
  }

  tryAddingNewPipe = (generator) => {
    if (!this.pipes.length) return;
    const { pipe } = this.pipes[this.pipes.length - 1];
    if (-pipe.position.x >= Settings.pipeHorizontalGap) {
      this.addNewPipe(generator);
    }
  };

  moveAll = (delta) => {
    let score = 0;
    for (let index = 0; index < this.pipes.length; index++) {
      this.move(index, delta);
      if (this.tryScoringPipe(index)) {
        score += 1;
      }
    }
    return score;
  };

  tryScoringPipe = index => {
    const group = this.pipes[index];

    if (
      !group.scored &&
      this.toGlobal(group.pipe.position).x < Settings.playerHorizontalPosition
    ) {
      group.scored = true;
      return true;
    }
    return false;
  };

  move = (index, delta) => {
    const { pipe, pipe2 } = this.pipes[index];
    pipe.position.x -= Settings.gameSpeed * delta;
    pipe2.position.x -= Settings.gameSpeed * delta;
  };

  setMaxMin = () => {
    const pipe = new Pipe(this.pipeTexture);
    const maxDelta = 400;
    let maxPosition =
      Settings.skyHeight -
      Settings.minPipeHeight -
      Settings.pipeVerticalGap -
      pipe.height / 2;
    Settings.maxPosition = Math.min(pipe.height / 2, maxPosition);
    Settings.minPosition = Math.max(Settings.minPipeHeight - pipe.height/2, Settings.height - Settings.groundHeight - Settings.pipeVerticalGap - pipe.height - pipe.height/2);
    let correction = Math.min(0, maxDelta - (Settings.maxPosition - Settings.minPosition + 1)) / 2;
    Settings.minPosition -= correction;
    Settings.maxPosition += correction;
  }

  addNewPipe = (generator) => {
    const pipeGroup = {};
    const pipe = new Pipe(this.pipeTexture);
    const pipe2 = new Pipe(this.pipeTexture);
    pipe.rotation = Math.PI;

    pipe.position.y = Math.floor(
      generator() * (Settings.maxPosition - Settings.minPosition + 1) + Settings.minPosition,
    );

    // console.log(minPosition, maxPosition, Settings.height - Settings.pipeVerticalGap - pipe.height);
    // pipe.position.y = Math.max(Settings.minPipeHeight - pipe.height/2, Settings.height - Settings.groundHeight - Settings.pipeVerticalGap - pipe.height - pipe.height/2);

    pipe2.position.y = pipe.height + pipe.position.y + Settings.pipeVerticalGap;
    pipe.position.x = pipe2.position.x = 0;
    pipe2.scale.x *= -1;

    pipeGroup.upper = pipe.position.y + pipe.height / 2;
    pipeGroup.lower = pipeGroup.upper + Settings.pipeVerticalGap;
    pipeGroup.pipe = pipe;
    pipeGroup.pipe2 = pipe2;

    this.addChild(pipe);
    this.addChild(pipe2);
    this.pipes.push(pipeGroup);
    this.tryRemovingLastGroup();
  };

  tryRemovingLastGroup = () => {
    if (
      this.pipes[0].pipe.position.x + Settings.pipeWidth / 2 >
      Settings.width
    ) {
      this.pipes.shift();
    }
  };

  setXforGroup = (index, x) => {
    const { pipe, pipe2 } = this.pipes[index];
    pipe.position.x = x;
    pipe2.position.x = x;
  };

  getX = index => {
    const { pipe } = this.pipes[index];
    return this.toGlobal(pipe.position).x;
  };

  restart = () => {
    this.pipeIndex = 0;
    this.pipes = [];
    this.children = [];
  };
}

class Pipe extends FlappySprite {
  constructor(texture) {
    super(texture);
    this.width = Settings.pipeWidth;
    this.height = Settings.pipeHeight;
    this.anchor.set(0.5);
  }
}

class Bird extends AnimatedSprite {
  constructor(textures) {
    super(textures);
    this.animationSpeed = 0.4;
    this.anchor.set(0.5);
    this.width = 60 * scale;
    this.height = 48 * scale;

    this.speedY = Settings.playerFallSpeed;
    this.rate = Settings.playerGravity;

    this.restart();
  }

  restart = () => {
    this.play();
    this.rotation = 0;
    this.position.x = Settings.playerHorizontalPosition;
    this.position.y = Settings.playerVerticalPosition;
  };

  updateGravity = (delta) => {
    this.position.y -= this.speedY * delta;
    this.speedY -= this.rate * delta;

    // const FLAP = 35;
    // this.rotation = -Math.min(
    //   Math.PI / 4,
    //   Math.max(-Math.PI / 2, (FLAP + this.speedY) / FLAP),
    // );
  };
}

class Game {
  stopAnimating = true;
  isStarted = false;
  isDead = false;
  score = 0;
  pos = 0;

  constructor(context) {
    // Sharp pixels
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

    this.app = new PIXI.Application({
      context,
      autoResize: false,
      width: context.drawingBufferWidth / 1,
      height: context.drawingBufferHeight / 1,
    });
    this.app.ticker.add(this.animate);
    /*
    this.app.stage.interactive = true;
    this.app.stage.buttonMode = true;
    this.app.stage.on('mousedown', this.beginGame);
    this.app.stage.on('tap', this.beginGame);
    */

    Settings.width = this.app.renderer.width;
    Settings.pipeScorePosition = -(
      Settings.width - Settings.playerHorizontalPosition
    );
    Settings.height = this.app.renderer.height;

    // Settings.groundHeight = Math.max(Settings.height - Settings.groundHeight - 800, 0);

    Settings.skyHeight = Settings.height - Settings.groundHeight;
    Settings.pipeHeight = Math.max(480 * scale, ((Settings.height) - Settings.pipeVerticalGap - Settings.groundHeight) / 1.2);
    Settings.pipeWidth = 78 * Settings.pipeHeight / 480;
    Settings.pipeHorizontalGap = Settings.pipeWidth * 3.325;
    this.loadAsync();
  }

  readyToDisplay = () => {
    this.ground.alpha = 1; 
    this.bird.alpha = 1; 
    this.stopAnimating = false;
  };

  // Resize function window
  resize = ({ width, height, scale }) => {
    const parent = this.app.view.parentNode;
    // Resize the renderer
    // this.app.renderer.resize(width * scale, height * scale);

    // if (Platform.OS === 'web') {
    //   this.app.view.style.width = width;
    //   this.app.view.style.height = height;
    // }
  };
  loadAsync = async () => {
    this.textures = await setupSpriteSheetAsync(source, sprites);


    this.textures['explosion_1'] = new global.PIXI.Texture(await Texture.fromExpoAsync(expl_1));
    this.textures['explosion_2'] = new global.PIXI.Texture(await Texture.fromExpoAsync(expl_2));
    this.textures['explosion_3'] = new global.PIXI.Texture(await Texture.fromExpoAsync(expl_3));
    this.textures['explosion_4'] = new global.PIXI.Texture(await Texture.fromExpoAsync(expl_4));
    this.textures['explosion_5'] = new global.PIXI.Texture(await Texture.fromExpoAsync(expl_5));
    this.textures['explosion_6'] = new global.PIXI.Texture(await Texture.fromExpoAsync(expl_6));
    this.textures['explosion_7'] = new global.PIXI.Texture(await Texture.fromExpoAsync(expl_7));
    
    this.onAssetsLoaded();
  };

  onAssetsLoaded = () => {
    this.background = new Background(this.textures.background);
    this.pipeContainer = new PipeContainer(this.textures.pipe);
    this.ground = new Ground(this.textures.ground);

    this.pipeContainer.setMaxMin();

    this.bird = new Bird([
      this.textures['bird_000'],
      this.textures['bird_001'],
    ]);

    this.explosion = new ExplosionSprite([
      this.textures['explosion_1'],
      this.textures['explosion_2'],
      this.textures['explosion_3'],
      this.textures['explosion_4'],
      this.textures['explosion_5'],
      this.textures['explosion_6'],
      this.textures['explosion_7'],
    ]);

    [this.background, this.pipeContainer, this.ground, this.bird, this.explosion].map(child =>
      this.app.stage.addChild(child),
    );
    

    this.ground.alpha = 0; 
    this.bird.alpha = 0; 
    this.explosion.alpha = 0;
    this.stopAnimating = true;
  };

  onPress = () => {
    if (!this.isSelfReady()) return;
    if (this.isDead) {
      this.restart();
      this.onRevive();
    } else {
      this.beginGame();
    }
  };

  beginGame = () => {
    if (!this.isStarted) {
      this.isStarted = true;
      this.score = 0;
      this.onScore(this.score, this.pos);
      this.pipeContainer.addNewPipe(this.genPos);
    }
    this.bird.speedY = Settings.playerFallSpeed;
    const pipe = new Pipe(this.pipeTexture);
    this.pos = (this.bird.position.y) / ( Settings.pipeVerticalGap / 2 + pipe.height/2 + ((Settings.maxPosition - Settings.minPosition + 1) + Settings.minPosition));
    this.onJump(this.pos);
  };

  animate = (delta) => {
    if (this.stopAnimating) {
      return;
    }

    delta = Math.round(delta * 100) / 100;

    if (!this.isDead) {
      if (Math.abs(this.ground.tilePosition.x) > this.ground.width) {
        this.ground.tilePosition.x = 0;
      }
      this.ground.tilePosition.x -= Settings.gameSpeed * delta;
    }

    if (this.isStarted) {
      this.bird.updateGravity(delta);
    }

    if (this.isDead) {
      this.bird.rotation += Math.PI / 4;
      if (
        this.bird.rotation > Math.PI / 2 &&
        this.bird.position.y > Settings.skyHeight - this.bird.height / 2
      ) {
        saveHighScoreAsync(this.score);
        this.stopAnimating = true;
      }
    } else {
      
      if (this.bird.position.y + this.bird.height / 2 > Settings.skyHeight || 0 > this.bird.position.y + this.bird.height / 2) {
        this.hitPipe();
      }

      const points = this.pipeContainer.moveAll(delta);
      if (points) {
        this.score += points;
        const pipe = new Pipe(this.pipeTexture);
        this.pos = (this.bird.position.y) / ( Settings.pipeVerticalGap / 2 + pipe.height/2 + ((Settings.maxPosition - Settings.minPosition + 1) + Settings.minPosition));
        this.onScore(this.score, this.pos);
      }
      this.pipeContainer.tryAddingNewPipe(this.genPos);

      const padding = 15;
      for (const group of this.pipeContainer.pipes) {
        const { pipe, pipe2, upper, lower } = group;
        if (
          boxesIntersect(this.bird, pipe, padding) ||
          boxesIntersect(this.bird, pipe2, padding)
        ) {
          this.hitPipe();
        }
      }
    }
  };

  restart = () => {
    this.isStarted = false;
    this.isDead = false;
    this.stopAnimating = false;
    this.score = 0;
    this.onScore(this.score, this.pos);
    this.bird.restart();
    this.pipeContainer.restart();
    this.animate(0);
  };

  hitPipe = () => {
    this.bird.stop();
    this.isDead = true;
    this.explosion.restart(this.bird.position.x, this.bird.position.y);
    this.onDied();
  };

  updateScore = () => {
    this.score += 1;
    this.onScore(this.score, this.pos);
  };
}

async function saveHighScoreAsync(score) {
  const highScore = await getHighScoreAsync();
  if (score > highScore) {
    await AsyncStorage.setItem('hiscore', highScore);
  }
  return {
    score: Math.max(score, highScore),
    isBest: score > highScore,
  };
}

async function getHighScoreAsync() {
  const score = await AsyncStorage.getItem('hiscore');
  if (score) {
    return parseInt(score);
  }
  return 0;
}

export default Game;
