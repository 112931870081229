import { GLView } from 'expo';
import * as React from 'react';
import { Text, TouchableWithoutFeedback, View, Image, PixelRatio, Dimensions } from 'react-native';

import DisableBodyScrollingView from './components/DisableBodyScrollingView';
// import ExpoButton from './components/ExpoButton';
// import GithubButton from './components/GithubButton';
import KeyboardControlsView from './components/KeyboardControlsView';
// import logyo from './components/logyo';
import Game from './src/game';
import Web3 from './components/Web3';
import DieScreen from './components/DieScreen';
import Network from './components/Network';
import * as Font from 'expo-font';
import Sound from './components/Sounds';

import soundOnTexture from './assets/soundon.png';
import soundOffTexture from './assets/soundoff.png';
// import maxListenersExceededWarning from 'max-listeners-exceeded-warning';
 
// maxListenersExceededWarning();
 
function Score({children}) {
  return (
    <Text
      style={{
          position: 'absolute',
          left: 0,
          top: '10%',
          right: 0,
          textAlign: 'center',
          color: 'white',
          fontSize: 48,
          userSelect: 'none',
          fontFamily: 'Fiery-Turk',
      }}
    >{children}</Text>
  );
}

class RandSin {
  constructor(seed) {
    this.seed = seed;
  }
  gen = () => {
    var x = Math.sin(this.seed) * 10000;
    this.seed++;
    return x - Math.floor(x);
  }
}

export default class App extends React.Component {
  state = {
    score: 0,
    prevScore: 0,
    loggedIn: false,
    session: null,
    died: false,
    generator: null,
    netReady: false,
    pos: 0,
    init: false,
    width: 1,
    height: 1,
    smthPressed: 0,
    soundOn: true,
    jumps: 0,
  };
  canvas = React.createRef();
  componentDidMount() {
    Font.loadAsync({
      'Fiery-Turk': require('./assets/fonts/Fiery_Turk.ttf'),
    }).then(_ => {});
  }
  
  render() {
    const { style, ...props } = this.props;

    const setPressedSmth = (() => {
      let p = this.state.smthPressed + 1;
      this.setState({ ...this.state, smthPressed: p });
    }).bind(this);

    const dim = Dimensions.get("window");

    let maxHeight = 900 * PixelRatio.get();
    let maxWidth  = 500 * PixelRatio.get();
    if (maxHeight > dim.height) {
      maxHeight = dim.height;
      maxWidth = dim.height * 5 / 9;
    }

    return (
      <View style={{ width: '100vw', height: '100vh', alignContent: 'center', alignItems: 'center', backgroundColor: '#090b0f', margin: 0, }}>
        <View
          onLayout={(event) => this.setState({ ...this.state, width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height })}
          style={[{ width: '100vw', height: '100vh', overflow: 'hidden', maxHeight, maxWidth, }, style]}
        >
          <DisableBodyScrollingView>
            <KeyboardControlsView
              onKeyDown={({ code }) => {
                if (this.game) {
                  // if (code === 'Space') {
                  //   this.setState({ ...this.state, died: false });
                  //   this.game.onPress();
                  // }
                }
              }}
            >
              <TouchableWithoutFeedback
                onPressIn={() => {
                  if (this.game && !this.state.died) this.game.onPress();
                }}
              >
                <GLView
                  style={{ flex: 1, backgroundColor: 'black' }}
                  onContextCreate={context => {
                    this.game = new Game(context);
                    this.game.onScore = (score, pos) => {
                      this.setState({ ...this.state, score, pos });
                    };
                    this.game.onDied = _ => {
                      this.setState({ ...this.state, died: true, prevScore: this.state.score, netReady: false, jumps: 0 });
                    };
                    this.game.onJump = (pos) => {
                      this.setState({ ...this.state, pos: pos, jumps: this.state.jumps + 1 });
                    };
                    this.game.onRevive = _ => {
                      this.setState({ ...this.state, died: false, score: 0, smthPressed: this.state.smthPressed + 1 });
                    };
                    this.game.genPos = _ => {
                      let rn = this.state.generator.gen();
                      return rn;
                    };
                    this.game.isSelfReady = _ => {
                      return this.state.netReady;
                    };
                  }}
                />
              </TouchableWithoutFeedback>
              {(this.state.loggedIn && !this.state.died && this.state.netReady) ? <Score>{this.state.score}</Score> : null }
            </KeyboardControlsView>
          </DisableBodyScrollingView>
          <Network jumps={this.state.jumps} score={this.state.score} pos={this.state.pos} died={this.state.died} session={this.state.session} loggedIn={this.state.loggedIn} setRandomness={(rn) => {
            // if (!this.state.init) {
            //   this.game.readyToDisplay();
            // }
            this.setState({ ...this.state, generator: new RandSin(rn), netReady: true, init: true });
          }} />
          <DieScreen setPressedSmth={setPressedSmth} w={this.state.width} h={this.state.height} score={this.state.score} callback={_ => { this.game.onPress(); }} died={this.state.died} session={this.state.session} prevScore={this.state.prevScore} />
          <Web3 setPressedSmth={setPressedSmth} w={this.state.width} h={this.state.height} loggedIn={this.state.loggedIn} onLoggedIn={() => {
            this.setState({ ...this.state, loggedIn: true, died: true })
          }} setSession={(session) => this.setState({ ...this.state, session })} netReady={this.state.init} />
          <Sound soundOn={this.state.soundOn} pressed={this.state.smthPressed} init={this.state.init} died={this.state.died} />
          <SoundDisable  h={this.state.height} soundOn={this.state.soundOn} onPress={_ => this.setState({ ...this.state, soundOn: !this.state.soundOn })} />
        </View>
      </View>
    );
  }
}

function SoundDisable({ h, soundOn, onPress }) {
  return (
      <View style={{ position: 'absolute', bottom: h/30,  alignContent: 'center', alignItems: 'center', width: '100%' }}>
        <TouchableWithoutFeedback onPress={onPress}>
          <View>
            {(soundOn) ? (<Image source={soundOnTexture} style={{ width: 32, height: 32 }}/>) : (<Image source={soundOffTexture} style={{ width: 32, height: 32 }} />)}
          </View>
        </TouchableWithoutFeedback>
      </View>

  )
}
