
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, TouchableWithoutFeedback, View, Dimensions, Image } from 'react-native';
import { API_URL } from './URL';
import source from '../assets/Score.png';
import retrySource from '../assets/Retry.png';
import leaderboardButtonSource from '../assets/LeaderboardButton.png';
import leaderboardSource from '../assets/Leaderboard.png';

const useSoreState = (s, prevScore) => {

    let [maxScore, setMaxScore] = useState(null);

    useEffect(_ => {
        if (!s) return;
        fetchMaxScore(s);
    }, [s]);

    useEffect(_ => {
        if (maxScore === null) return;
        if (prevScore > maxScore) {
            updateScore(prevScore);
        }
    }, [prevScore, maxScore]);

    const fetchMaxScore = (session) => {
        getMaxScore(session).then(setMaxScore);
    };

    const updateScore = (score) => {
        // let lastMax = maxScore;
        setMaxScore(score);
        // axios.put(API_URL + '/score?score=' + score, {}, {
        //     headers: {
        //       'Gateway-Session': s,
        //     }
        //   }).then(r => {
        //     if (r.data.error) {
        //         console.error(r.data.error);
        //     }
        // });
    };

    const getMaxScore = async (session) => {
        let r = await axios.get(API_URL + '/score', {
            headers: {
              'Gateway-Session': session,
            }
        });
        if (r.data.error) {
            console.error(r.data.error);
            throw new Error(r.data.error);
        }
        return r.data.score;
    };

    const getLeaderboard = async () => {
        let r = await axios.get(API_URL + '/leaderboard', {
            headers: {
              'Gateway-Session': s,
            }
        });
        if (r.data.error) {
            console.error(r.data.error);
            throw new Error(r.data.error);
        }
        return r.data.leaderboard;
    };

    return {
        maxScore,
        getLeaderboard,
    }

}

const fullSizeViewStyles = {
    position: 'absolute', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flex: 1,
}


const useDim = (w, h) => {
    const [dim, setDim] = useState({ width: 1, height: 1 });

    useEffect(_ => {
        setDim({ width: w, height: h });
    }, [w, h]);
    
    const height = (dim.height / 1.67 < dim.width) ? dim.height : dim.width * 1.67;
    const width = (dim.height / 1.67 < dim.width) ? dim.height / 1.67 : dim.width;
    const scale = width / 1000;
    return { width, height, dim, scale }
};


function FullSizeView({ w, h, ...props }) {

    const dim = Dimensions.get('window');

    return <View 
        style={{ 
            width: w, 
            height: h, 
            ...fullSizeViewStyles,
        }} 
        { ...props }
    />;
}

function Leaderboard({ callback, setShowLeaderboard, getLeaderboard, w, h, setPressedSmth }) {

    const { width, height } = useDim(w, h);
    const [lbValues, setLbValues] = useState(null);

    useState(_ => {
        // let vals = [];
        // for (let i = 0; i < 25; i++) {
        //     vals.push({
        //         id: i,
        //         score: Math.floor(Math.random() * 100)
        //     });
        // }
        // setLbValues(vals);
        getLeaderboard().then(setLbValues);
    }, []);

    return (
        <>
            <FullSizeView w={w} h={h}>
                <Image
                    style={{ opacity: 1, userSelect: 'none', width, height  }}
                    source={leaderboardSource}
                />
            </FullSizeView>
            <FullSizeView w={w} h={h}>
                <View style={{ opacity: 1, userSelect: 'none', width, height, alignItems: 'center'   }}>
                    {(!lbValues) ? <Text style={{
                                position: 'absolute',
                                top: height/5.5,
                                color: 'white',
                                fontSize: 14 * height / 500,
                                userSelect: 'none',
                                fontFamily: 'Fiery-Turk',
                        }}>Loading..</Text>
                        : lbValues.map((v, i) => (
                        <View key={i} style={{ position: 'absolute', top: height/6 + i * height/41, width: width * 0.58 }}>
                            <Text style={{
                                position: 'absolute',
                                textAlign: 'left',
                                left: 0,
                                color: 'black',
                                fontSize: 9 * height / 500,
                                userSelect: 'none',
                                fontFamily: 'Fiery-Turk',
                            }}>#{v.id}</Text>
                            <Text style={{
                                position: 'absolute',
                                textAlign: 'right',
                                right: 0,
                                color: 'white',
                                fontSize: 9 * height / 500,
                                userSelect: 'none',
                                fontFamily: 'Fiery-Turk',
                            }}>{v.score}</Text>
                        </View>
                    ))}

                </View>
            </FullSizeView>
            <FullSizeView w={w} h={h}>
                <TouchableWithoutFeedback style={{ 
                    width: '100%', 
                    height: '100%',
                    alignItems: 'center', justifyContent: 'center',
                }} onPress={_ => {
                        setShowLeaderboard(false);
                        callback();
                    }}
                >
                    <View w={w} h={h} style={{ alignItems: 'center', justifyContent: 'center', width: width, height: height }}>
                        <Image
                            style={{ position: 'absolute', opacity: 1, userSelect: 'none', width: width/4, height: height/20, marginTop: height*0.7 }}
                            source={retrySource}
                        />
                    </View>
                </TouchableWithoutFeedback>
            </FullSizeView>
        </>
      );
}

export default ({ died, session, callback, prevScore, score, w, h, setPressedSmth }) => {

    const { maxScore, getLeaderboard } = useSoreState(session, prevScore);
    const { width, height } = useDim(w, h);
    const [showLeaderboard, setShowLeaderboard] = useState(true);

    if (!died) return null;

    if (showLeaderboard) {
        return <Leaderboard setPressedSmth={setPressedSmth} w={w} h={h} callback={callback} getLeaderboard={getLeaderboard} setShowLeaderboard={setShowLeaderboard} />
    }

    return (
        <>
            <FullSizeView w={w} h={h}>
                <Image
                    style={{ opacity: 1, userSelect: 'none', width, height  }}
                    source={source}
                />
            </FullSizeView>
            <FullSizeView w={w} h={h}>
                <View style={{ opacity: 1, userSelect: 'none', width, height, justifyContent: 'center', alignItems: 'center'  }}>
                    <Text style={{
                        position: 'absolute',
                        top: height/3.3,
                        color: 'black',
                        fontSize: 18 * height / 500,
                        userSelect: 'none',
                        fontFamily: 'Fiery-Turk',
                    }}>{score}</Text>

                    <Text style={{
                        position: 'absolute',
                        top: height/2.5,
                        color: 'black',
                        fontSize: 18 * height / 500,
                        userSelect: 'none',
                        fontFamily: 'Fiery-Turk',
                    }}>{maxScore}</Text>
                </View>
            </FullSizeView>
            <FullSizeView w={w} h={h}>
                <TouchableWithoutFeedback onPress={_ => {callback();}} >
                    <Image
                        style={{ position: 'absolute', opacity: 1, userSelect: 'none', width: width/4, height: height/20, marginTop: height/6 }}
                        source={retrySource}
                    />
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback onPress={_ => {setPressedSmth(); setShowLeaderboard(true)}}>
                    <Image
                        style={{ position: 'absolute', opacity: 1, userSelect: 'none', width: width/2, height: height/20, marginTop: height/3 }}
                        source={leaderboardButtonSource}
                    />
                </TouchableWithoutFeedback>
            </FullSizeView>
        </>
      );

}