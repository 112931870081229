import { Audio } from 'expo-av';
import React from 'react';

export default ({ init, died, pressed, soundOn }) => {
    const [soundBackground, setSound] = React.useState(null);
    const [soundBackground2, setSound2] = React.useState(null);
    // const [hitSound, setHitSound] = React.useState();
    // const [pressSound, setPressSound] = React.useState();


    async function initSound() {
        const sound1 = await Audio.Sound.createAsync(
            require('../assets/audio/MAINMENU3.m4a')
        );
        const sound2 = await Audio.Sound.createAsync(
            require('../assets/audio/policesound.wav')
        );
        setSound(sound1.sound);
        setSound2(sound2.sound); 
    }

    async function playSound1() {
        await soundBackground.setIsLoopingAsync(true);
        await soundBackground.playAsync(); 
        
    }

    async function playSound2() {
        await soundBackground2.setIsLoopingAsync(true);
        await soundBackground2.playAsync(); 
    }

    async function stopSound1() {
        await soundBackground.stopAsync(); 
    }

    async function stopSound2() {
        await soundBackground2.stopAsync();  
    }

    // async function playHitSound() {
    //     // const { sound } = await Audio.Sound.createAsync(
    //     //     require('../assets/audio/CrashGround.wav')
    //     // );
    //     // setHitSound(sound);
    //     // await sound.playAsync(); 
    // }

    // async function playPressed() {
    //     // const { sound } = await Audio.Sound.createAsync(
    //     //     require('../assets/audio/UI.wav')
    //     // );
    //     // setPressSound(sound);
    //     // await sound.playAsync(); 
    // }

    React.useEffect(() => {
        if (init) initSound().then(_ => {});
    }, [init]);

    React.useEffect(() => {
        if (init) {
            if (!soundOn) {
                stopSound1();
                stopSound2();
            } else {
                playSound1();
                playSound2();
            }
        }
    }, [soundOn]);

    React.useEffect(() => {
        if (soundBackground && soundOn) playSound1();
    }, [soundBackground]);

    React.useEffect(() => {
        if (soundBackground2 && soundOn) playSound2();
    }, [soundBackground2]);

    // React.useEffect(() => {
    //     if (init) {
    //         if (died) {
    //             playHitSound().then(_ => {});
    //         }
    //     }
    // }, [init, died]);

    // React.useEffect(() => {
    //     if (pressed === 0) return;
    //     playPressed().then(_ => {});
    // }, [pressed]);

  return null;
}