// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { useWalletConnect, withWalletConnect } from '@walletconnect/react-native-dapp';
import React, { useState, useRef, useEffect } from 'react';
import { Platform, Button, View, Image, Dimensions, TouchableOpacity, Text, Animated, Easing  } from 'react-native';
import Web3 from 'web3';
import source from '../assets/UI.png';
import sourcePlay from '../assets/ConnectButton.png';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from './URL'
import droneSource0 from '../assets/DroneAnimated.png';


const fullSizeViewStyles = {
    position: 'absolute', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flex: 1,
}

const useDim = (w, h) => {
    const [dim, setDim] = useState({ width: 1, height: 1 });

    useEffect(_ => {
        setDim({ width: w, height: h });
    }, [w, h]);
    
    const height = (dim.height / 1.67 < dim.width) ? dim.height : dim.width * 1.67;
    const width = (dim.height / 1.67 < dim.width) ? dim.height / 1.67 : dim.width;
    const scale = width / 1000;
    return { width, height, dim, scale }
};


function AnimatedDrones({ w, h }) {

    // const fadeAnim = useRef(new Animated.Value(0)).current;
    const { width, height, scale } = useDim(w, h);

    const [ k0, k1, k2, k3, k4, k5 ] = [
        new Animated.Value(0),
        new Animated.Value(0),
        new Animated.Value(0),
        new Animated.Value(0),
        new Animated.Value(0),
        new Animated.Value(0),
    ];

    const makeAnim = (s, k) => Animated.sequence([Animated.delay(1000 * Math.random()), Animated.loop(
        Animated.sequence([
            Animated.timing(k, {
                toValue: s,
                duration: 1500,
            }),
            Animated.timing(k, {
                toValue: 0,
                duration: 1500,
            })
        ]))
    ]);

    const anim = Animated.parallel([
        makeAnim((30 + (Math.random() * 30)) * scale, k0),
        makeAnim((30 + (Math.random() * 30)) * scale, k1),
        makeAnim((30 + (Math.random() * 30)) * scale, k2),
        makeAnim((30 + (Math.random() * 30)) * scale, k3),
        makeAnim((30 + (Math.random() * 30)) * scale, k4),
        makeAnim((30 + (Math.random() * 30)) * scale, k5),
    ]);
    
    useEffect(_ => {
        anim.start();
    }, [w, h]);

    const animatedDrone = (k, top, right) => (
        <Animated.Image 
            source={droneSource0}
            style={{ position: 'absolute', width: 118 * scale, height: 83 * scale, top, right, transform: [{ translateY: k }] }}
        />
    );

    return (
        <FullSizeView>
            <View style={{ width: width, height: height, alignContent: 'center', alignItems: 'center'}}>
                {animatedDrone(k0, height / 1.8,  width / 2.6)}
                {animatedDrone(k1, height / 2.3,  width / 1.7)}
                {animatedDrone(k2, height / 1.7,  width / 1.2 )}
                {animatedDrone(k3, height / 2.6,  width / 8.0 )}
                {animatedDrone(k4, height / 1.6,  width / 6.0 )}
                {animatedDrone(k5, height / 3.9,  width / 1.3 )}
            </View>
        </FullSizeView>
    )
}

function FullSizeView({ w, h, ...props }) {

    return <View 
        style={{ 
            width: w, 
            height: h, 
            ...fullSizeViewStyles,
        }} 
        { ...props }
    />;
}

function FullSizeTouchableView({ onPress, w, h, ...props }) {

    return <TouchableOpacity
        onPress={onPress} 
        style={{ 
            width: w, 
            height: h, 
            ...fullSizeViewStyles,
        }} 
        { ...props }
    />;
}


export default ({ loggedIn, onLoggedIn, setSession, netReady, w, h, setPressedSmth }) => {

    const [web3] = useState(new Web3(window.ethereum) || null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMessage] = useState(null);
    const { width, height } = useDim(w, h);

    const notDone = false;

    const signAccount = async ({ account, nonce, accessKey }) => {
        const msgParams = [
            {
                type: 'string',     
                name: 'code',    
                value: nonce.toString(), 
            },
        ];

        // Sign nonce
        let security = await web3.currentProvider.request({
            method: 'eth_signTypedData',
            params: [msgParams, account],
            from: account,
        });

        // Request login
        let sessionInfo = await axios.get(API_URL + '/login', { params: { security, accessKey } });

        if (!!sessionInfo.data.error) {
            console.error(sessionInfo.data.error);
            throw new Error(sessionInfo.data.error);
        }

        await AsyncStorage.setItem('@gatewaySession', sessionInfo.data.gatewaySession);
        setSession(sessionInfo.data.gatewaySession);

    };

    const verifyAuth = async (sess) => {
        let res = await axios.get(API_URL + '/validate', {
            headers: {
              'Gateway-Session': sess,
            }
        });
        return res.data.status;
    }

    const initiateAuth = async () => {
        // Request for an account 
        let accs = await window.ethereum.request({ method: 'eth_requestAccounts' });
        let account = accs[0];
        // Generate new nonce
        let nonceInfo = await axios.get(API_URL + '/nonce/' + account);
        if (!!nonceInfo.data.error) {
            console.error(nonceInfo.data.error);
            throw new Error(nonceInfo.data.error);
        }
        // Process sign
        await signAccount({ account, ...nonceInfo.data });
    };

    const requestLoginSteps = () => {
        initiateAuth().then(_ => {
            setIsLoading(false);
            onLoggedIn();
        }, e => {
            /* Error handling */
            setIsLoading(false);
            if (e.message) {
                setErrorMessage(e.message.toString());
            } else {
                setErrorMessage(e.toString());
            }
        });
    };

    const emitLogin = () => {
        setPressedSmth();
        if (!web3) return;
        if (errorMsg !== null) {
            setErrorMessage(null);
            return;
        }
        setIsLoading(true);
        AsyncStorage.getItem('@gatewaySession').then(s => {
            if (s === null) {
                requestLoginSteps();
            } else {
                verifyAuth(s).then(res => {
                    if (res) {
                        setSession(s);
                        setIsLoading(false);
                        onLoggedIn();
                    } else {
                        AsyncStorage.removeItem('@gatewaySession').then(requestLoginSteps);
                    }
                }, requestLoginSteps)
            }
        })
    };

    if (loggedIn && netReady) {
        return null;
    }
        
    return (
        <>
            <FullSizeView  w={w} h={h}>
                <Image
                    style={{ opacity: 1, userSelect: 'none', width, height  }}
                    source={source}
                />
            </FullSizeView>
            <AnimatedDrones w={w} h={h} />
            {
                (notDone) ? (<FullSizeView  w={w} h={h}><Text style={{ marginTop: height/2, color: '#ffffff', fontSize: 20, fontFamily: 'Fiery-Turk'}}>
                {"ISSUES BEING FIXED"}
            </Text></FullSizeView>) : (Platform.OS === 'web' && !!window.ethereum && !isLoading && (!loggedIn)) ? <FullSizeTouchableView  w={w} h={h} onPress={emitLogin}>
                {(errorMsg === null) ? <Image
                    style={{ opacity: 1, userSelect: 'none', width, height  }}
                    source={sourcePlay}
                /> : <Text style={{ marginTop: height/2, color: '#ffffff', fontSize: 20, fontFamily: 'Fiery-Turk'}}>
                    {errorMsg}
                </Text>}
            </FullSizeTouchableView> : <FullSizeView  w={w} h={h}>
                <Text style={{ marginTop: height/2, color: '#ffffff', fontSize: 20, fontFamily: 'Fiery-Turk'}}>
                    {(isLoading) ? "Connecting.." : (!!window.ethereum) ? "Loading.." : "Install Metamask to use this App!"}
                </Text>
            </FullSizeView>
            }
        </>

    );
    // }
    // return <Button title="Install Metamask first!" />;
}

// export default withWalletConnect(App, {
//   redirectUrl: Platform.OS === 'web' ? window.location.origin : 'yourappscheme://',
//   storageOptions: {
//     asyncStorage: AsyncStorage,
//   },
// });

// const providerOptions = {
//     walletconnect: {
//       package: WalletConnectProvider,
//       options: {
//         infuraId: "0abfac0d1f354185903fcdd9d98b96aa"
//       }
//     }
// };

// const web3ModalState = atom({
//   key: 'web3Modal',
//   default: new Web3Modal({
//     network: "mainnet",
//     cacheProvider: true,
//     providerOptions
//   }),
//   dangerouslyAllowMutability: true,
// });


// const web3State = atom({
//   key: 'web3',
//   default: null,
//   dangerouslyAllowMutability: true,
// });

// function useProviderSetup() {

//     const [_, setAccount] = useRecoilState(accountState);
//     const [web3Modal] = useRecoilState(web3ModalState);
//     const [web3, setWeb3] = useRecoilState(web3State);

//     const resetAccount = () => {
//         setAccount({
//             loggedIn: false,
//             address: '0x0',
//         });
//     };

//     useEffect(() => {
//         if (web3 === null) return;
//         web3.eth.getAccounts().then(accountList => {
//             setAccount({
//                 loggedIn: true,
//                 address: accountList[0]
//             });
//         });
//     }, [web3, setAccount]);

//     const enableProvider = async () => {

//         const provider = await web3Modal.connect();

//         provider.on("accountsChanged", (accounts) => {
//             if (accounts.length === 0) {
//                 resetAccount();
//                 return;
//             } 
//             setAccount({
//                 loggedIn: true,
//                 address: accounts[0],
//             });
//         });

//         provider.on("chainChanged", (chainId) => {
//             if ('0x1' !== chainId) {
//                 resetAccount();
//             }
//         });

//         provider.on("disconnect", (error) => {
//             resetAccount();
//         });

//         await provider.enable();
//         setWeb3(new Web3(provider));

//     };

//     return [ enableProvider ];
// }


// export { useProviderSetup }
